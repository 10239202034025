.rc-time-picker-input {
    padding: 18px 12px;
    font-weight: 500;
}
.rc-time-picker-panel {
    z-index: 1200;
}
.rc-time-picker-clear {
    display: none;
}
.rc-time-picker-panel-select li {
    font-family: 'Poppins';
}